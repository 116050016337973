<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <div>
            <b-form  @submit.prevent="fetch(1)">
              
              <b-row>
                <b-col>
                  <b-form-input class="mr-1" v-model="filter.q"
                                placeholder="Recherche par Marque, Modèle, Immat, Client..."/>
                </b-col>
<!--                <b-col v-if="$can('voir-vendu-concession', 'vendu') || $can('voir-vendu-global', 'vendu')">-->
<!--                  <v-select :reduce="item => item.id" v-model="filter.concession_id" label="libelle" :options="$store.state.initialConfigModule.initialState.concessions" placeholder="Rechercher par concession"></v-select>-->
<!--                </b-col>-->
<!--                <b-col v-if="$can('voir-vendu-concession', 'vendu') || $can('voir-vendu-site', 'vendu') || $can('voir-vendu-global', 'vendu')">-->
<!--                  <v-select :reduce="item => item.id" v-model="filter.site_id" label="libelle" :options="$store.state.initialConfigModule.initialState.sites" placeholder="Rechercher par site"></v-select>-->
<!--                </b-col>-->
                <b-col md="auto">
                  <b-button type="submit" variant="primary">Recherche</b-button>
                  <b-button v-if="$can('exporter-vendu', 'vendu')"  :disabled="pendingExport" @click="exportHandler" class="ml-1"  variant="primary">
                    <b-spinner small v-if="pendingExport"></b-spinner>
                    Exporter</b-button>

                </b-col>
              </b-row>
             
              
            </b-form>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-overlay :show="is_loading">
          <b-card

              no-body
          >

            <div class="m-2 d-flex justify-content-between">

              <!-- Table Top -->  
              <div class="align-self-center">
                {{pagination.total}} éléments
              </div>

            </div>
            <b-table v-if="items"
                     ref="selectableTable"
                     :fields="fields"
                     :items="items"

                     responsive
                     class="mb-0"

            >

              <template #cell(vehicule)="data">
                <vehicule-overview :vehicule="data.item.vehicule"/>
              </template>

            </b-table>


          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row v-if="items.length == 0">
      <b-col>
        <b-card class="text-center">
          Aucun véhicule trouvé dans vendu
        </b-card>
      </b-col>
    </b-row>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
              v-model="pagination.current_page"
              :total-rows="pagination.total"
              :per-page="pagination.per_page"
              first-number
              last-number
              @change="fetch"
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
 
  </div>

</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BButton, BFormGroup, BAvatar, BBadge, BCardBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import VehiculeOverview from "@/components/Vehicule/VehiculeOverview";
import axios from "@/libs/axios";


export default {
  components: {
    VehiculeOverview,
    BCardCode,
    BTable,
    BButton,
    BFormGroup,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pendingExport : false,
      fields: ['id', {
        key: 'vehicule',
        label: 'Véhicule'
      },
        {
          key: 'prix_vente',
          label: 'Prix de vente'
        }
      ],
      items: [],
      filter: {
        q: ''
      },
      pagination : {},
      page : 1,
      is_loading : false,
    }
  },
  created() {
    this.fetch(1);
  },
  methods: {
    exportHandler(){
      let _this = this;
      _this.pendingExport = true;
      axios.get(
          process.env.VUE_APP_API_URL + 'api/vehicules/vendu?export=1&q=' + _this.filter.q,
          {responseType: 'blob'} 
      ).then((response) => {
        _this.pendingExport = false;
        window.open(URL.createObjectURL(response.data));
      });
    },
    fetch(page) {
      let _this = this;
      _this.is_loading = true;
      this.$store.dispatch('venduModule/fetchVendu', {
        page: page,
        ..._this.filter
      }).then(function (response) {
        _this.items = response.data;
        _this.pagination = response.meta;
        _this.is_loading  = false;
      })
    }
  },
}
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}

</style>
